import React from 'react'
import '../assets/scroll.css';
import "../assets/fonts/fonts.css";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
    <div class="flex flex-row justify-center h-fit w-full bg-red-900">
          
          
          <footer  class="text-center lg:text-left bg-red-900 text-white fontCust3 ">
  <div class="flex justify-center items-center lg:justify-between p-6 border-b border-t border-gray-300">
    <div class="mr-12 hidden text-lg lg:block">
      <span>Contactez nous sur nos réseaux :</span>
    </div>
    <div class="flex justify-center">
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
          class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512">
          <path fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
          </path>
        </svg>
      </a>
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
          class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
          </path>
        </svg>
      </a>
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
          <path fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
          </path>
        </svg>
      </a>
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
          </path>
        </svg>
      </a>
      <a href="#!" class="text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
          </path>
        </svg>
      </a>
    </div>
  </div>
  <div class="mx-6 py-10 text-center md:text-left">
    <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div class="">
        <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
          
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
</svg>
          Iguard, sécurité incendie
        </h6>
        <p>
          Votre sécurité est notre priorité et nous nous en portant garants.
        </p>
      </div>
      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Produits
        </h6>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Extincteurs</div>
        </HashLink>
        </p>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Porte coupe-feu</div>
        </HashLink>
        </p>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Trappes</div>
          </HashLink>
        </p>
        <p>
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Rideaux</div>
        </HashLink>
        </p>
      </div>
      

      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Liens utiles
        </h6>
        <p class="mb-4">
          <div class="text-white hover:text-gray-300 hover:cursor-not-allowed transition duration-200">Boutique</div>
        </p>
        <p class="mb-4">
        <Link to="/devis">
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Devis urgent</div>
        </Link>
        </p>
        <p class="mb-4">
        <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Certifications</div>
        </HashLink>
        </p>
        <p>
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Formations</div>
        </HashLink>
        </p>
      </div>
      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Contact
        </h6>
        <Link to="/plan">
        <p class="flex items-center justify-center md:justify-start mb-4 hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor"
              d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
            </path>
          </svg>
          8 Quai Adrien Agnés, 93300 Aubervilliers
        </p>
        </Link>
        <a href="mailto:contact@iguardincendie.fr" class="flex items-center justify-center md:justify-start mb-4 hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
            </path>
          </svg>
          {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`}
        </a>
        <a href="tel:+33134291449" class="flex items-center justify-center md:justify-start mb-4 tracking-widest hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
            class="w-4 mr-4 transform rotate-90" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
            </path>
          </svg>
          01 34 29 14 49
        </a>
        <p class="flex items-center justify-center md:justify-start">
          
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-12a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 1 0 1.415-1.415L11 9.586V6z"
                  />
                </svg>
          de 8h à 18h30 en semaine
        </p>
      </div>

      {/** Layout contact mobile */}
      <div class="flex flex-row w-full sm:hidden">
      <div class="flex flex-col w-full sm:hidden">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Produits
        </h6>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Extincteurs</div>
        </HashLink>
        </p>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Porte coupe-feu</div>
        </HashLink>
        </p>
        <p class="mb-4">
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Trappes</div>
        </HashLink>
        </p>
        <p>
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Rideaux</div>
        </HashLink>
        </p>
      </div>
      <div class="flex flex-col w-full sm:hidden">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Liens utiles
        </h6>
        <p class="mb-4">
          <div class="text-white hover:text-gray-300 hover:cursor-not-allowed transition duration-200">Boutique</div>
        </p>
        <p class="mb-4">
        <Link to="/devis">
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Devis urgent</div>
        </Link>
        </p>
        <p class="mb-4">
        <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Certifications</div>
        </HashLink>
        </p>
        <p>
        <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Formations</div>
        </HashLink>
        </p>
      </div>
      </div>
      <div class="block  sm:hidden pl-12">
        <h6 class="uppercase font-semibold mb-4 flex justify-start">
          Contact
        </h6>
        <Link to="/plan">
        <p class="flex items-center justify-start mb-4 text-sm hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor"
              d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
            </path>
          </svg>
          8 Quai Adrien Agnés, 93300 Aubervilliers
        </p>
        </Link>
        <a href="mailto:contact@iguardincendie.fr" class="flex items-center justify-start mb-4 tracking-widest hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
            </path>
          </svg>
          {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`}
        </a>
        <a href="tel:+33134291449" class="flex items-center justify-start mb-4 tracking-widest hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
            class="w-4 mr-4 transform rotate-90" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
            </path>
          </svg>
          01 34 29 14 49
        </a>
        <p class="flex items-center justify-start">
          
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-12a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 1 0 1.415-1.415L11 9.586V6z"
                  />
                </svg>
          de 8h à 18h30 en semaine
        </p>
      </div>
    </div>
  </div>
</footer>
       
         
          </div>
          <div class="w-full cred">
        <div className="container text-sm px-6 py-4 mx-auto text-center">
          <div>
            <p className="text-center text-white fontCust3">
              Réalisé par Sophiane L — Copyright © 2022 — Tous droits réservés
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
