import './App.css';


import { Router, Route, Routes, useLocation } from "react-router-dom";
import Home from './components/Home';
import Devis from './components/Devis';
import Recrutement from './components/Recrutement';
import Plan from './components/Plan';
import ErreurPage from './components/ErreurPage';
import { useLayoutEffect } from 'react'

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);
  return children
} 



function App() {
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="devis" element={<Devis />} />
        <Route path="recrutement" element={<Recrutement />} />
        <Route path="plan" element={<Plan />} />
        <Route path="*" element={<ErreurPage/>} />
      </Routes>
      </Wrapper>
  );
}

export default App;
