import React, { useState, useEffect, useRef } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReCAPTCHA from "react-google-recaptcha";
 




import Logo from "../assets/logo_iguard.png";
import Logow from "../assets/logo_iguard.webp";
import cross from "../assets/cross.png";
import crossw from "../assets/cross.webp";
import Hotel from "../assets/hotel.jpg";
import Hotelw from "../assets/hotel.webp";
import Residence from "../assets/residence.jpg";
import Residencew from "../assets/residence.webp";
import Hopital from "../assets/hopital.jpg";
import Hopitalw from "../assets/hopital.webp";
import Ecole from "../assets/ecole.jpg";
import Ecolew from "../assets/ecole.webp";
import Restaurant from "../assets/restaurant.jpg";
import Restaurantw from "../assets/restaurant.webp";
import Usine from "../assets/usine.jpg";
import Usinew from "../assets/usine.webp";
import Construction from "../assets/construction.jpg";
import Constructionw from "../assets/construction.webp";
import Entreprise from "../assets/entreprise.jpg";
import Entreprisew from "../assets/entreprise.webp";
import Contact from "../assets/elec.jpg";
import Contactw from "../assets/elec.webp";
import Carousel from "./Carousel";
import data from "./data";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import '../assets/scroll.css';
import "../assets/fonts/fonts.css";







const Home = () => {
  
  const boite = useRef();
  const navRef = React.useRef(null);
  const resc = useRef();
  const refNor = useRef();
  const refRef = useRef();
  const [ferme, setFerme] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const recaptchaRef = React.createRef();
  const [cliquable, setCliquable] = useState(false);
  const [warning, setWarning] = useState(false);

  const showSidebar = (e) => {
      navRef.current.classList.remove("translate-x-96");
      boite.current.classList.remove("hidden");
    };
  
    const hideSidebar = (e) => {
      navRef.current.classList.add("translate-x-96");
      boite.current.classList.add("hidden");
    };
  
    const useClickOutside = (handler) => {
      let outsideSidebar = useRef();
  
      useEffect(() => {
        const maybeHandler = (event) => {
          if (!outsideSidebar.current.contains(event.target)) {
            handler();
          }
        };
  
        document.addEventListener("mousedown", maybeHandler);
  
        return () => {
          document.removeEventListener("mousedown", maybeHandler);
        };
      });
  
      return outsideSidebar;
    };
  
    let outsideSidebar = useClickOutside(() => {
      hideSidebar();
    });

  const [toggleState, setToggleState] = useState(1);

	const toggleTab = (index) => {
		setToggleState(index);
	};

  useEffect(() => {
    Aos.init({ duration: 500});
  }, []);

  


   const manageText = (ind) => {
    switch(ind) {
      case 1:
        return "Moyens d'extinction";
      case 2:
        return "Désenfumage";
      case 3:
        return "Signalétique";
      case 4:
        return "Détection incendie";
      case 5:
        return "Éclairage de sécurité";
      case 6:
        return "Robinet incendie armé";
      case 7:
        return "Porte coupe-feu";
      case 8:
        return "Formation";
      default : 
        return "";
        
    }
   }

  const DropDown = () =>{
    return (
      <div class="w-full flex flex-col justify-center lg:hidden">
      
      <Menu>
      
        <div class="flex items-center flex-col">
        <div class=" w-full py-1 flex flex-row border-2 border-black bg-white hover:bg-blue-500 transition duration-200 hover:text-white rounded-lg fontCust3 text-lg">
          <Menu.Button class="  text-left w-full   rounded-lg">
            <div class="flex flex-row">
            <div class="pl-3 w-full">{manageText(toggleState)}</div>
            <div class="w-1/6 h-full pt-1  flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          </div>
            </Menu.Button>
          
        </div>
        
        <div class="absolute z-50 w-5/6 mt-10">
        
        <Menu.Items>
        <div class="flex flex-col bg-white border w-full mt-2 rounded-2xl space-y-2 fontCust3">
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer rounded-tl-2xl rounded-tr-2xl pl-4" onClick={() => toggleTab(1)}>Moyens d'extinction</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(2)}>Désenfumage</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(3)}>Signalétique</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(4)}>Détection incendie</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(5)}>Éclairage de sécurité</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(6)}>Robinet incendie armé</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer pl-4" onClick={() => toggleTab(7)}>Porte coupe-feu</div> 
        </Menu.Item>
  
        <Menu.Item>
          <div class="w-full h-full py-1 text-lg hover:bg-blue-600 hover:text-white hover:cursor-pointer rounded-bl-2xl rounded-br-2xl pl-4" onClick={() => toggleTab(8)}>Formation</div> 
        </Menu.Item>
       
        </div>
      
        </Menu.Items>
        
        </div>
        </div>
      </Menu>
      
      </div>
    )
  }

  const refNosServ = useRef(null);
  const refCont = useRef(null);
  

  const goToNosServ = () =>{
     if(refNosServ.current){
       hideSidebar();
     refNosServ.current.scrollIntoView({ behavior: "smooth", block: "start" });
     }
  } 

  const goToTab = (ind) =>{
    if(refNosServ.current){
    hideSidebar();
    toggleTab(ind);
    refNosServ.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
 } 

  const goToCont = () =>{
    if(refCont.current){
      hideSidebar();
      refCont.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
 } 

 const goToLyc = () =>{
  if(resc.current){
    
    resc.current.scrollIntoView({ behavior: "smooth", block: "start" });
    setFerme(!ferme);
  }
}

const goToNorm = () =>{
  if(refNor.current){
    hideSidebar();
    refNor.current.scrollIntoView({ behavior: "smooth", block: "start" });
    
  }
} 

const goToR = () =>{
  if(refRef.current){
    hideSidebar();
    refRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    
  }
} 

const [navbar, setNavbar] = useState(false);

const changeBackground = () => {
  if (window.scrollY >= 118) {
    setNavbar(true);
  } else {
    setNavbar(false);
  }
};

window.addEventListener("scroll", changeBackground);
window.addEventListener('load', Aos.refresh)

const goToTop = () =>{
    hideSidebar();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
} 




const { register, handleSubmit, reset, formState: { errors, dirtyFields } } = useForm({
  mode: "onChange",
  defaultValues: {
    email: "",
    phone: "",
    message: "",
    name : "",
  }
  });
const form = useRef();

const showConfirm = () => {
  if(isVisible){return;}
  else{
    setVisible(true);
    setTimeout(()=> setVisible(false) , 2500);
  }
}

const showWarning = () => {
  if(warning){return;}
  else{
    setWarning(true);
    setTimeout(()=> setWarning(false) , 5000);
  }
}

const sendEmail = (data) => {
  if(cliquable){
  emailjs.sendForm('service_4aahyeb', 'template_e8wgb69', form.current, '3ROAX5nFzhZgCe45b')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    reset();
    showConfirm();
  }else {
    showWarning();
    return;
  }
    
};





return(
  <div class="global_container">
    {/** Navbar */}
    <div
            class="h-screen w-screen fixed opacity-100 z-50  top-0 backdrop-filter backdrop-blur-sm backdrop-brightness-75 md:hidden hidden"
            ref={boite}
          ></div>
    <div ref={outsideSidebar}>
            <div
              class="sidebar min-h-screen xl:translate-x-96 flex fixed right-0 transform translate-x-96 transition duration-200 ease-in-out z-50"
              ref={navRef}
            >
              <div class=" bg-white w-72">
                <div class="bg-red-800 text-white border-b  text-xl flex items-center h-20">
                  <button >
                    <picture class="h-6 w-6 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" alt="fermer sidebar">
                          <source srcSet={crossw} type="image/webp" />
                          <img onClick={hideSidebar} src={cross} alt="fermer sidebar" class="h-6 w-6 -left-10 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" ></img>
                    </picture>
                  </button>
                  <div class="flex justify-center items-center w-full h-full">
                    <div class="fontCust3 text-2xl font-semibold"><b>IGUARD</b></div>
                  </div>
                </div>
                <nav>
                  <button onClick={goToTop}
                    class="block w-full text-md py-6 px-4 fontCust3 border-b  hover:text-blue-700 hover:bg-gray-100 transition duration-200"
                  >
                    Présentation
                  </button>
                  <button onClick={goToNosServ}
                    class="block w-full text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Nos services
                  </button>
                  <button onClick={goToNorm}
                    class="block w-full text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  > 
                    Certification normes et garanties
                  </button>
                  <Link to="/devis"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Devis urgent
                  </Link>
                  <Link to="/recrutement"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Recrutement
                  </Link>
                  <Link to="/plan"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Plan d'accès
                  </Link>
                  <button onClick={goToCont}
                    class="block w-full text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Nous contacter
                  </button>
                  
                </nav>
              </div>
            </div>
          </div>
<div class="h-8 w-full bg-red-700">
  <div class="h-full lg:w-5/6 w-full flex justify-center items-center md:justify-end">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
  </svg>
    <a href="tel:+33134291449" class=" text-white fontCust3 font-bold tracking-wider mr-6 gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> 01.34.29.14.49 </a>
    
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
</svg>
    <a href="mailto:contact@iguardincendie.fr" class=" text-white fontCust3 font-bold tracking-wider gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`} </a>
    
  </div>
</div>


{/**fixed z-50 w-full -mt-8 */}

<nav class={navbar ? "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10 fixed z-40 w-full -mt-8" : "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10" }> 
      <div class="container flex flex-wrap justify-between items-center mx-auto">
      <div class="h-full flex items-center">
        <Link to="/">
            <picture class="h-16" alt="logo entreprise <b>IGUARD</b>">
                          <source srcSet={Logow} type="image/webp" />
                          <img src={Logo} alt="logo entreprise <b>IGUARD</b>" class="h-16 hover:opacity-80 hover:cursor-pointer transition duration-200" ></img>
                    </picture>
            </Link>
            </div>
            <div onClick={showSidebar} class="lg:hidden flex h-full justify-end items-center pr-6 hover:cursor-pointer hover:-translate-x-2 transition duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            </div>
      <div class="hidden justify-center items-center w-full lg:flex md:w-auto md:order-1" id="mobile-menu-2">
        <ul class="flex flex-col md:flex-row md:space-x-4 md:mt-0 lg:text-md xl:text-lg text-sm md:font-medium">
          
          <li>
          
            <button onClick={goToTop} class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Présentation</button>
          
          </li>
          <li>
            <div class="drpd">
            <button onClick={goToNosServ} class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nos services ▾ </button>
            <div class="drpd-menu w-64 z-50 fontCust3 ">
              <div onClick={()=>goToTab(8)} class="drpd-menu-item pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3">Formation</div>
              <div onClick={()=>goToTab(3)} class="drpd-menu-item px-3 py-2">Audit</div>
              <div onClick={()=>goToTab(1)} class="drpd-menu-item px-3 py-2">Moyens d'extinction</div>
              <div onClick={()=>goToTab(2)} class="drpd-menu-item px-3 py-2">Désenfumage</div>
              <div onClick={()=>goToTab(3)} class="drpd-menu-item px-3 py-2">Signalétique</div>
              <div onClick={()=>goToTab(4)} class="drpd-menu-item px-3 py-2">Détection incendie</div>
              <div onClick={()=>goToTab(5)} class="drpd-menu-item px-3 py-2">Éclairage de sécurité</div>
              <div onClick={()=>goToTab(6)} class="drpd-menu-item px-3 py-2">Robinet incendie armé</div>
              <div onClick={()=>goToTab(7)} class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Porte coupe-feu</div>
            </div>
            </div>
          </li>
          <li>
          <div class="drpd">
            <button onClick={goToNorm} class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Certifications normes et garanties ▾ </button>
            <div class="drpd-menu w-64 xl:w-80 z-50 fontCust3">
              <div onClick={goToR} class="drpd-menu-item py-2 rounded-xl px-3">Nos références</div>
            </div>
            </div>
          </li>
          <li>
            <Link to="/devis" class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Devis urgent</Link>
          </li>
          <li>
          <div class="drpd">
            <div class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 hover:cursor-not-allowed ">Boutique ▾ </div>
            <div class="drpd-menu-b w-64 z-50 fontCust3">
              <div class="drpd-menu-item-b pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3 hover:cursor-not-allowed">Extincteurs</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Détecteurs de fumée</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Alarmes incendie</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">BAES BAEH</div>
              <div class="drpd-menu-item-b px-3 rounded-bl-xl rounded-br-xl py-1 hover:cursor-not-allowed">Accessoires extincteur</div>
            </div>
            </div>
          </li>
          <li>
          <div class="drpd">
            <button onClick={goToCont} class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nous contacter ▾ </button>
            <div class="drpd-menu-n w-64 z-50 fontCust3">
            <Link to="/plan">
              <div class="drpd-menu-item py-2 rounded-tl-xl rounded-tr-xl px-3">Plan d'acces</div>
              </Link>
              <Link to="/recrutement">
              <div class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Recrutement</div>
              </Link>
            </div>
            </div>
          </li>
          
          {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg> */}
        </ul>
      </div>
      </div>
</nav>

    

{/** Sliders */}




<div class="w-full car flex justify-center mt-10 md:mt-20">
  <div class="w-5/6 lg:w-4/5 xl:w-3/5  relative justify-center">
   <div class="flex w-full h-full justify-center ">
      <Carousel images={data} refCont={refCont}/>
   </div>
</div>
</div>
<div class="w-full justify-center hidden md:flex mt-12">
<div class="fontCust2 text-2xl tracking-widest md:text-3xl font-bold xl:w-3/5 w-5/6 "><h2 class="w-fit  p-1 bg-red-800 text-white"><b>IGUARD</b> Protection Incendie, garant de votre sécurité.</h2></div>
</div>

<div class="w-full justify-center items-center flex md:hidden flex-col mt-12">
<h2 class="fontCust2 text-2xl tracking-widest md:text-3xl font-bold xl:w-3/5 w-5/6 text-center w-fit  p-1 bg-red-800 text-white"><b>IGUARD</b> Protection Incendie.</h2>
<h2 class="fontCust2 text-slate-500 tracking-widest md:text-3xl font-bold xl:w-3/5 w-5/6 mt-2 italic">Garant de votre sécurité</h2>
</div>
<div class="w-full flex justify-center mt-3">
  <div class="xl:w-3/5 w-5/6 flex flex-col fontCust3 text-slate-900 mb-3 md:mb-12 text-md md:text-lg lg:text-xl ">
   <div class="mt-2"> Implantée en région parisienne à Aubervilliers, <b><b>IGUARD</b></b> est une entreprise française spécialisée dans la <b>protection incendie</b>.</div>
<div class="mt-2">Nous proposons  la vente de matériels de <b>prévention incendie (extincteur, désenfumage, alarmes incendie)</b> ainsi que la <b>maintenance</b> de ces appareils. 
<div class="mt-2"><b> IGUARD</b> propose également d'assurer l'audit de votre <b>protection incendie</b> ainsi que celle de votre <b>signalétique</b>, nous sommes aussi à votre disposition afin de <b>former</b> votre personnel à manipuler le matériel et réagir en cas d'incendie.</div></div>
<div class="mt-2"> <b>IGUARD</b> assure à sa clientèle professionnelle, comme particuliers, un service d’écoute et de conseil comprenant l’ensemble des critères nécessaires à une protection la plus approprié dans vos projets.</div>

  </div>
  </div>
  
  <div class="bg-gray-200/50 h-fit w-full border-t mt-10 pb-20">
  <div class="h-12 w-full flex justify-center items-center -mt-6">
          <div id="nosServ" class={navbar ? "px-4 py-2 absolute bg-black flex justify-center items-center fontCust4 tracking-widest text-white text-2xl md:text-4xl z-30 scroll-m-24": "px-4 py-2 absolute bg-black flex justify-center items-center fontCust4 tracking-widest text-white text-2xl md:text-4xl z-30 scroll-m-48"} ref={refNosServ}>Nos services</div>
          </div>



<div class="flex flex-row justify-center h-full w-full mt-10">
          <div class="w-5/6">
                <div class="w-full h-12 hidden lg:flex flex-row text-xs lg:text-xs 2xl:text-lg  text-white fontCust3">
                <button class={ toggleState === 1 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-l border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r border-l border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(1)}> Moyens d'extinction </button>
                <button class={ toggleState === 2 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(2)}> Désenfumage </button>
                <button class={ toggleState === 3 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(3)}> Signalétique </button>
                <button class={ toggleState === 4 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(4)}> Détection incendie </button>
                <button class={ toggleState === 5 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(5)}> Éclairage de sécurité </button>
                <button class={ toggleState === 6 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(6)}> Robinet incendie armé </button>
                <button class={ toggleState === 7 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(7)}> Porte coupe feu </button>
                <button class={ toggleState === 8 ? 'w-full h-full flex justify-center items-center bg-red-900 border-r border-white/50 hover:cursor-pointer transition duration-200' : 'w-full h-full flex justify-center items-center bg-slate-900 border-r  border-white/50 hover:bg-red-900 hover:cursor-pointer transition duration-200'} onClick={() => toggleTab(8)}> Formation </button>
                
          
                </div>
                <DropDown/>
                

                {/** Exctinction */}
                <div  class={toggleState === 1 ? "mt-10 h-full" : "hidden"}>
                  
                  
                <div class="flex flex-col md:flex-row">
                  <div class="w-full">
                  <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Moyens d’extinction</div>
                  
                  <div class="mt-6 fontCust3 text-xl w-fit font-semibold">Une gamme complète de 1 à 100kg/L.</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Extincteurs eau</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Extincteurs poudre</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Extincteurs CO2</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Extincteur bioversal (nouveau)</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Extincteurs sur roues</div>

                  
                  <Link to="/devis">
                  <div  class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest ">Contactez nous</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                  </div>
                  <div  class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                  <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                  
                  <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>


                  </div>

                  </div>

                </div>

                {/** Désenfumage */}
                <div class={toggleState === 2 ? "mt-10 h-full" : "hidden"}>
                  
                  
                <div class="flex flex-col md:flex-row">
                  <div class="w-full">
                  <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Désenfumage naturel et mécanique</div>
                  
                  <div class="mt-6 fontCust3 text-xl w-fit font-semibold"><b>IGUARD</b> assure l’étude, l’installation et la maintenance de sytème de désenfumage naturel et mécanique selon les normes en vigueur.</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Châssis ouvrant en façade</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Exutoire en toiture</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Asservissement mécanique part reuil</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Par CO2</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Asservissement électrique par dad a cage d’escalier ou par centrale de détection</div>


                  <Link to="/devis">
                  <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest ">Contactez nous</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                  </Link>
                  </div>
                  <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                  <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                  
                  <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>


                  </div>

                  </div>

                </div>

                {/** Signalétique */}
                <div class={toggleState === 3 ? "mt-10 h-full" : "hidden"}>
                  
                  
                <div class="flex flex-col md:flex-row">
                  <div class="w-full">
                  <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Signalétique</div>
                  
                  <div class="mt-6 fontCust3 text-xl w-fit font-semibold">Plan de sécurité incendie.</div>
                  <div class="mt-1 fontCust3 text-md italic w-fit">Plan généralement affiché à l’intention des occupants d’un établissement sur lequel sont illustrés les éléments nécessaires à l’évacuation et sur lequel peuvent figurer les informations nécessaires au sauvetage, à la lutte contre un début d’incendie et au secours.</div>

                  <div class="mt-3 fontCust3 text-xl w-fit font-semibold">Plan d’évacuation sectoriel</div>
                  <div class="mt-1 fontCust3 text-md italic w-fit">Plan d’évacuation comportant une représentation partielle du niveau concerné et un report de cette zone sur un petit plan de situation figurant à côté.</div>

                  <div class="mt-3 fontCust3 text-xl w-fit font-semibold">Plan de situation et orientation</div>
                  <div class="mt-1 fontCust3 text-md italic w-fit">Représentation graphique simplifiée utilisée pour relier le plan d’évacuation sectoriel à l’ensemble du local, de l’établissement ou du site.</div>
                  
                  <div class="mt-3 fontCust3 text-xl w-fit font-semibold">Plan d’intervention</div>
                  <div class="mt-1 fontCust3 text-md italic w-fit">Plan généralement affiché et destiné à apporter les informations nécessaires pour faciliter l’intervention, en cas d’urgence,des services de secours internes (équipiers d’intervention, …) et/ou externes (sapeurs-pompiers, …) Sa conception permet une lecture opérationnelle, dans l’urgence.</div>

                  <div class="mt-3 fontCust3 text-xl w-fit font-semibold">Plan technique de sécurité</div>
                  <div class="mt-1 fontCust3 text-md italic w-fit">Plan à usage professionnel (services de secours, installateurs, mainteneurs, utilisateurs, …) destiné à transmettre une information ou une instruction technique sur les équipements de sécurité. Ces plans ne sont généralement pas affichés car ils s’adressent à des lecteurs initiés. Ce sont par exemple les plans d’implantation des équipements, les plans du dossier de conformité d’un ERP, les plans d’établissements répertoriés ou de prévision opérationnelle, les plans de cantons de désenfumage, plans SSI…</div>
                  
                  <div class="mt-3 fontCust3 text-md md:text-lg lg:text-xl w-fit font-semibold">Notre service est à votre disposition pour vous conseiller dans l’élaboration de vos consignes ainsi que de tous supports et demandes spécifiques selon vos besoins.</div>


                  
                  <Link to="/devis">
                  <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-md font-bold text-white fontCust2 tracking-widest ">Demandez votre audit signalétique</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                  </div>
                  <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                  <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                  
                  <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                  <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>


                  </div>

                  </div>

                </div>

                 {/** Detection incendie */}
                 <div class={toggleState === 4 ? "mt-10 h-full" : "hidden"}>
                  
                  
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full">
                    <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Détection incendie</div>
                    
                    <div class="mt-6 fontCust3 text-xl w-fit font-semibold">Notre équipe vous accompagne à chaque stade en choisissant l’installation la plus adaptée à votre catégorie d’établissement.</div>
                    <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Tableau de détection incendie conventionnels et adressables</div>
                    <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- SSI</div>
                    <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Alarme type 1, 2a, 2b, 3, 4</div>
                    <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Daaf</div>

                    <div class="mt-6 fontCust3 text-xl w-fit font-semibold">Domaine d’application</div>
                    <div class="mt-1 fontCust3 text-xl w-fit">Le règlement de sécurité contre l’incendie est applicable dans les établissements, on distingue :</div>
                    <div class="mt-1 fontCust3 text-xl w-fit">- Les immeubles d’habitation</div>
                    <div class="mt-1 fontCust3 text-xl w-fit">- Les établissements recevant du public du premier groupe (de la 1er a la 4ème catégorie)</div>
                    <div class="mt-1 fontCust3 text-xl w-fit">- Les établissements recevant du public du second groupe  (5ème catégorie)</div>
                    <div class="mt-1 fontCust3 text-xl w-fit">- Les immeubles de grande hauteur</div>
  
                    <Link to="/devis">
                    <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest "><b>IGUARD</b> vous rappelle</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                    </div>
                    <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                    <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                    
                    <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>
  
  
                    </div>
  
                    </div>
  
                  </div>

                  {/** Éclairage */}
                 <div class={toggleState === 5 ? "mt-10 h-full" : "hidden"}>
                  
                  
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full">
                    <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Eclairage de sécurité</div>
                    
                    <div class="mt-6 fontCust3 text-xl w-fit">Les établissement doivent disposer d’un éclairage de sécurite conforme par la réglementation en vigueur permettant d’assurer l’évacuation des personnes en cas d’interruption accidentelle de l’éclairage normal.</div>
                    <div class="mt-6 fontCust3 text-xl w-fit font-semibold">Nous recommandons une vérification annuelle selon la norme NFC 71-830.</div>
                    
                    <Link to="/devis">
                    <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest "><b>IGUARD</b> vous rappelle</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                    </div>
                    <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                    <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                    
                    <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>
  
  
                    </div>
  
                    </div>
  
                  </div>


                  {/** Robinet */}
                 <div class={toggleState === 6 ? "mt-10 h-full" : "hidden"}>
                  
                  
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full">
                    <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Robinet incendie armé</div>
                    
                    <div class="mt-6 fontCust3 text-xl text-slate-900 w-fit"><b>IGUARD</b> offre une large gamme de RIA équipement de première intervention particulièrement efficace déclinés sous armoire, fixe ou pivotant.</div>
                    <div class="mt-6 fontCust3 text-xl text-slate-900 w-fit font-semibold">Nous pouvons répondre aux spécificités de chaque installation.</div>
                    
                    <Link to="/devis">
                    <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest ">Contactez nous</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                    </div>
                    <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                    <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                    
                    <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>
  
  
                    </div>
  
                    </div>
  
                  </div>

                  {/** Porte coupe-feu */}
                <div class={toggleState === 7 ? "mt-10 h-full" : "hidden"}>
                  
                  
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full">
                    <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Porte coupe-feu</div>
                    
                    <div class="mt-6 fontCust3 text-xl w-fit">Une large gamme pour lutter contre la propagation de l’incendie et protéger les personnes contre la fumée toxique.</div>
                    <div class="mt-1 fontCust3 text-xl w-fit font-semibold">La réglementation impose à certains bâtiments ERP de disposer de divers moyens actifs et passifs pour protéger les utilisateurs,elle participe au cloisonnement des locaux qui limite la propagation du feu.</div>
                    
                    <div class="mt-3 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Portes coulissantes</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Portes standardes</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Portes battantes</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Trappes de visites</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Trappes guillotines</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Portes basculantes</div>
                  <div class="mt-1 fontCust3 text-xl hover:underline hover:cursor-pointer w-fit">- Rideaux tissus et métalliques irrigués ou non irrigués</div>
  
  
                  <Link to="/devis">
                    <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-md font-bold text-white fontCust2 tracking-widest ">Demandez votre audit coupe-feu</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                    </div>
                    <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                    <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                    
                    <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>
  
  
                    </div>
  
                    </div>
  
                  </div>

                  {/** Formation */}
                <div class={toggleState === 8 ? "mt-10 h-full" : "hidden"}>
                  
                  
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full">
                    <div class="fontCust3 text-2xl md:text-3xl lg:text-4xl text-slate-900 font-semibold">Formation</div>
                    
                    <div class="mt-6 fontCust3 text-xl w-fit">Besoin d’une formation pour votre personnel ?</div>
                    <div class="mt-1 fontCust3 text-xl w-fit font-semibold"><b>IGUARD</b> propose un ensemble de formation adaptées à vos risques. Traditionnel ou en véhicule nous nous engageons à informer vos salariés :</div>
                    
                    <div class="mt-3 fontCust3 text-xl w-fit">- Savoir réagir</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Les bons réflexes</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Évacuation</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Manipulation</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Premiers secours</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Comprendre le feu</div>
                  <div class="mt-1 fontCust3 text-xl w-fit">- Accessoires</div>
  
                  <Link to="/devis">
                  <div class="mt-6 sm:ml-3 flex flex-row border-2 rounded border-red-800/50 bg-red-900 hover:bg-red-800 sm:hover:bg-red-900 w-full justify-center items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest "><b>IGUARD</b> vous rappelle</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    </Link>
                    </div>
                    <div class=" pb-3 w-fit h-fit shadow-xl bg-red-700 text-white mt-10 md:mt-0">
                    <div class="pl-3 py-2 fontCust2 tracking-widest text-lg md:text-xl lg:text-2xl font-semibold bg-red-900">Rappel catégorie de feux</div>
                    
                    <div class="pl-3 mt-2 fontCust2 tracking-widest text-md md:text-lg lg:text-xl w-fit"> <b>A</b> – Feux de solides, bois, papier, carton, tissu… </div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>B</b> – Feux de liquides ou de solides liquéfiables, hydrocarbure, huiles, alcool, peintures, plastiques…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>C</b>  – Feux de gaz gaz de ville, butane,propane…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>D</b>  – Feux de métaux magnésium, aluminium, sodium…</div>
                    <div class=" pl-3 mt-1 fontCust2 tracking-widest  text-md md:text-lg lg:text-xl w-fit"><b>F</b> – Feux d’huile de cuisson, graisse végétales, graisses animales…</div>
  
  
                    </div>
  
                    </div>
  
                  </div>


                  
                </div>
                </div>
                
  
  </div>
  <div class="h-fit w-full border-t">
  <div class="h-12 w-full flex justify-center items-center -mt-6">
          <div class="px-4 py-2 absolute bg-black flex justify-center items-center fontCust4 tracking-widest text-white text-2xl md:text-4xl z-30">Votre confiance se mérite</div>
          </div>
          </div>
          <div class="flex flex-row justify-center h-fit w-full mt-10 pb-10" data-aos="fade-up">
          <div class="w-5/6">
          <div  class="mt-3 text-md md:text-lg lg:text-xl fontCust3 text-slate-900 font-semibold">Votre confiance, nous nous devons de la mériter et c’est la raison pour laquelle nous mettons tout en œuvre pour vous proposer un service réactif et professionnel.</div>
          <div class="text-md md:text-lg lg:text-xl fontCust3 text-slate-900 font-semibold">Nous vous apportons les solutions pour maîtriser le risque incendie à chacun de ses stades.</div>
          
          <div id="normes" class={navbar ? "text-2xl xs:text-3xl text-center md:text-left lg:text-4xl font-bold text-slate-900 fontCust2 tracking-widest mt-6 scroll-m-24" :"text-2xl xs:text-3xl text-center md:text-left lg:text-4xl font-bold text-slate-900 fontCust2 tracking-widest mt-6 scroll-m-72" } ref={refNor}>Normes et garanties</div>

          <div class="text-sm md:text-md lg:text-lg fontCust3 mt-4">
          Tous les produits sortis d’usine sont strictement conformes, en tous points, aux modèles déposés et certifiés par le laboratoire du CNPP. Chaque réservoir est testé unitairement au moins à 25 bars. C’est votre garantie en tant que professionnel et la tranquillité pour l’utilisateur final.
          <br/>Du 20 mars 2002, portant sur la mise en application obligatoire de normes, les extincteurs d’incendie portatifs doivent être conformes à la norme <b>NF EN 3-1, 2, 3, 4, 5 ou équivalents</b>.</div>
          <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 ">L’avis du 3 décembre 2008 émis par le Ministère de l’Economie, de l’Industrie et de l’Emploi, confirme que l’<b>AFNOR Certification</b>, qui délivre la certification NF, est le référentiel validé par le code de la consommation sur la certification des produits industriels et des services.
                       
                      </div>

                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 font-bold">Afin de garantir le bon fonctionnement des extincteurs :
                       
                      </div>

                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 italic">
                      • Marquage CE obligatoire pour tous les extincteurs fabriqués depuis le 29 mai 2002, attestant de la conformité des appareils à la directive équipements sous-pression transposée par le décret du 13 décembre 1999.
                      </div>

                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 italic">
                      • Directive 97/23/CE d’application obligatoire permettant de vérifier la résistance mécanique du réservoir et du couvercle.
                      </div>

                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 italic">
                      • Norme européenne EN-3 permet, à un instant T, dans des conditions particulières, la vérification de l’aptitude à l’emploi, la conformité des composants extincteurs tels que déclarés en amont par le fabricant et de valider l’efficacité d’extinction en milieu de laboratoire.
                      </div>


                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 italic">
                      • Marque NF garantit grâce à des contrôles en usine inopinés et fréquents que les extincteurs fabriqués en série sont en tout points conformes à ceux testés en laboratoire dans le cadre de l’obtention de la certification EN3.
                      </div>

                      
                      <div class="text-sm md:text-md lg:text-lg fontCust3 mt-4 font-bold">
                      Cet ensemble de procédures de contrôle des usines de production (suivi de fabrication, prélèvement, analyses chimiques, contrôles de résistances mécaniques, tests de fonctionnement…) permet de garantir le respect des normes, le suivi de la production ainsi que la mise sur le marché d’un produit fiable et fonctionnel : c’est le label NF.</div>

                    <div id="references" class={navbar ? "text-2xl xs:text-3xl text-center md:text-left lg:text-4xl font-bold text-slate-900 fontCust2 tracking-widest mt-6 scroll-m-24" : "text-2xl xs:text-3xl text-center md:text-left lg:text-4xl font-bold text-slate-900 fontCust2 tracking-widest mt-6 scroll-m-72" } ref={refRef}>Nos domaines d'activités</div> 

                    <div data-aos="fade-down" class="flex flex-col lg:flex-row mt-4 md:mt-6 shadow-md p-3 ">
                    <picture class=" sm:max-w-xs w-full lg:mr-12 rounded" alt="porte chambre d'hotel">
                          <source srcSet={Hotelw} type="image/webp" />
                          <img src={Hotel} alt="porte chambre d'hotel" class="sizeImage sm:max-w-xs w-full lg:mr-12 border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div  class="text-2xl md:text-3xl font-semibold fontCust3 text-blue-800 mt-2 lg:mt-0">Hôtels</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Afin de recevoir et d'accueillir votre clientèle dans les conditions les plus sûres, <b>IGUARD</b> se charge de renforcer la sécurité incendie de vos hôtels par la mise en place de solutions de votre choix. <br/> <b>IGUARD</b> propose aussi la formation de votre personnel afin de réagir au plus vite en cas d'incendie.
                       
                      </div>
                    </div>
                    </div>

                    <div data-aos="fade-down" class="flex flex-col lg:flex-row-reverse mt-4 md:mt-12 shadow-md p-3">
                    <picture class="sm:max-w-xs w-full  lg:ml-12 mt-2 rounded" alt="porte chambre d'hotel">
                          <source srcSet={Residencew} type="image/webp" />
                          <img src={Residence} alt="porte chambre d'hotel" class="sizeImage sm:max-w-xs w-full border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                      
                    <div class="text-xl xs:text-2xl md:text-3xl md:text-right font-semibold fontCust3 text-blue-800 mt-2 md:mt-0">Immeubles & Résidences</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Dans les bâtiments d’habitation, étant donné l’absence de visite de sécurité des pompiers, le maintien de la qualité initiale « sécurité contre l’incendie » repose sur le propriétaire, <b>IGUARD</b> se porte garant de la sécurité et de la tranquilité d'esprit de vos locataires.
                       
                      </div>
                    </div>
                    
                    </div>

                    <div data-aos="fade-down" class="flex flex-col lg:flex-row mt-4 md:mt-12 shadow-md p-3">
                    <picture class=" sm:max-w-xs w-full lg:mr-12 rounded" alt="enfants devant une école">
                          <source srcSet={Ecolew} type="image/webp" />
                          <img src={Ecole} alt="enfants devant une école" class="sizeImage sm:max-w-xs w-full lg:mr-12 border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-2xl md:text-3xl font-semibold fontCust3 text-blue-800 mt-2 md:mt-0">Écoles & Lycées</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    La sécurité et le bien être des étudiants ainsi que des enseignants est notre priorité c'est pourquoi <b>IGUARD</b> se propose de mettre en place avec vous des solutions afin de guarantir leur sécurité. <br/> <b>IGUARD</b> se charge aussi de pratiquer un audit sur votre sécurité déjà existante. 
                       
                      </div>
                    </div>
                    </div>

                    <div data-aos="fade-down" class="flex flex-col lg:flex-row-reverse mt-4 md:mt-12 shadow-md p-3" ref={resc}>
                    <picture class="sm:max-w-xs w-full  lg:ml-12 mt-2 rounded" alt="médeçin dans chambre d'hopital">
                          <source srcSet={Hopitalw} type="image/webp" />
                          <img src={Hopital} alt="médeçin dans chambre d'hopital" class="sizeImage sm:max-w-xs w-full border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-2xl md:text-3xl font-semibold md:text-right fontCust3 text-blue-800 mt-2 md:mt-0">Hôpitaux & Cliniques</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Avec des patients agés ou dont l'état de santé est parfois critique, la protection incendie est primordiale afin de pratiquer des soins et de recevoir vos visiteurs dans des conditions adéquates. La société <b>IGUARD</b> guarantit la sécurité de vos hopitaux par le respect des normes de sécurité et la formation de votre personnel.  
                       
                      </div>
                    </div>
                    
                    </div>
                  
                    <div class={ferme ?  "w-full mt-6 flex justify-center items-center"  : "hidden"}>
                      <div onClick={()=>setFerme(!ferme)} class="flex flex-row justify-center items-center bg-blue-700 hover:scale-110 sm:border-b-4 border-slate-900 hover:bg-blue-600 w-full sm:w-fit hover:cursor-pointer transition duration-200 text-white fontCust3 text-2xl py-2 px-4 rounded">
                      <div  class="text-center mr-2">Voir plus</div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
</svg>
                      </div>
                    </div>

                    <SlideDown className={'my-dropdown-slidedown'} closed={ferme}>
                    <div  class="flex flex-col lg:flex-row mt-4 md:mt-12 shadow-md p-3">
                    <picture class=" sm:max-w-xs w-full lg:mr-12 rounded" alt="tables et chaises dans restaurant">
                          <source srcSet={Restaurantw} type="image/webp" />
                          <img src={Restaurant} alt="tables et chaises dans restaurant" class="sizeImage sm:max-w-xs w-full lg:mr-12 border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-2xl md:text-3xl font-semibold fontCust3 text-blue-800 mt-2 md:mt-0">Restauration</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Dans le domaine de la restauration ou vôtre personnel est amené à manipuler fours et autres moyens de cuisson, vôtre établissement se doit d'être le plus sûr et le plus aux normes possible en terme de sécurité incendie. <b>IGUARD</b> se propose de faire un audit de sécurité de vos restaurants, de vous fournir en extincteurs, alarmes et autres moyens afin de guarantir la sécurité de vôtre clientèle.
                       
                      </div>
                    </div>
                    </div>

                    <div  class="flex flex-col lg:flex-row-reverse mt-4 md:mt-12 shadow-md p-3">
                    <picture class="sm:max-w-xs w-full  lg:ml-12 mt-2 rounded" alt="transpalette dans une usine">
                          <source srcSet={Usinew} type="image/webp" />
                          <img src={Usine} alt="transpalette dans une usine" class="sizeImage sm:max-w-xs w-full border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-2xl sm:text-3xl font-semibold md:text-right fontCust3 text-blue-800 mt-2 md:mt-0">Industriel & Usines</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Les ouvriers dans les usines sont amenés à manipuler de la machinerie complexe qui dans certains cas comporte des risques d'incendies majeurs. <b>IGUARD</b> guarantit de fournir à vos employés des moyens afin qu'ils puissent travailler dans les meilleurs conditions en respectant les normes de protection incendie. 
                       
                      </div>
                    </div>
                    
                    </div>

                    <div  class="flex flex-col lg:flex-row mt-4 md:mt-12 shadow-md p-3">
                    <picture class=" sm:max-w-xs w-full lg:mr-12 rounded" alt="ouvrier sur un toit">
                          <source srcSet={Constructionw} type="image/webp" />
                          <img src={Construction} alt="ouvrier sur un toit" class="sizeImage sm:max-w-xs w-full lg:mr-12 border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-lg xs:text-2xl sm:text-3xl font-semibold fontCust3 text-blue-800 mt-2 md:mt-0">Construction & Environnement</div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    Dans vos chantiers, un incendie peut parfois grandement retarder vos échéances, c'est pourquoi <b>IGUARD</b> se propose de vous accompagner et de former vos ouvriers afin de réagir le plus rapidement possible. La société <b>IGUARD</b> vous fournit également en extincteurs, éclairages de sécurité, porte coupe-feux et autres moyens selon vos besoins.
                       
                      </div>
                    </div>
                    </div>
                    <div  class="flex flex-col lg:flex-row-reverse mt-4 md:mt-12 shadow-md p-3">
                    <picture class="sm:max-w-xs w-full  lg:ml-12 mt-2 rounded" alt="bureaux dans une entreprise">
                          <source srcSet={Entreprisew} type="image/webp" />
                          <img src={Entreprise} alt="bureaux dans une entreprise" class="sizeImage sm:max-w-xs w-full border rounded" ></img>
                    </picture>
                    <div class="flex flex-col">
                    <div class="text-lg md:text-3xl font-semibold md:text-right fontCust3 text-blue-800 mt-2 md:mt-0">Réferencement d’Entreprise </div> 
                    <div class="text-sm md:text-md lg:text-lg fontCust3 mt-2 md:mt-4">
                    En raison de la nature de leurs activités, certaines entreprises présentent un risque élevé d’incendie, c'est pourquoi <b>IGUARD</b> se charge de former vôtre personnel à la protection incendie, vous fournir en extincteurs et appareils de detection de fumée, de solutions de désenfumage ainsi que de porte coupe-feux. <br/> <b>IGUARD</b> se charge aussi de la maintenance de vos appareils.
                       
                      </div>
                    </div>
                    
                    </div>
                    </SlideDown>

                    

                    
                    
                    <div class={ferme ? "hidden" : "w-full mt-6 flex justify-center items-center"}>
                      <div onClick={goToLyc} class="flex flex-row w-full sm:w-fit justify-center items-center hover:scale-110 sm:border-b-4 border-slate-900 bg-red-800 hover:bg-red-700 hover:cursor-pointer transition duration-200 text-white fontCust3 text-2xl py-2 px-4 rounded">
                      <div  class="mr-2">Voir moins</div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
</svg>
                      </div>
                    </div>

                    

                   
                    
   
          
          </div>
          </div>

          <div id="contact" class={navbar ? "bg-red-900 h-fit w-full flex scroll-m-20": "bg-red-900 h-fit w-full flex scroll-m-48"} ref={refCont} >
            <div class="flex flex-col lg:flex-row w-full h-full ">
              <div data-aos="fade-up" data-aos-offset= "200" class="w-full border-r border-gray-200/50 my-8">
                  <div class=" mx-8 ">
                    <picture class="max-w-full rounded-xl" alt="ouvrier avec un casque jaune">
                          <source srcSet={Contactw} type="image/webp" />
                          <img src={Contact} alt="ouvrier avec un casque jaune" class="max-w-full rounded-xl" ></img>
                    </picture>
                    
                  </div>
              </div>
              <div class="w-full">
              <div data-aos="fade-down" data-aos-offset= "200" class="w-full mb-8 md:my-8">
                  <div class=" mx-8 ">
                    <div class="flex flex-col">
                      <div class="text-white lg:text-7xl text-4xl xs:text-5xl sm:text-6xl md:text-7xl fontCust5 tracking-widest mb-4 md:mb-8">Parlons nous</div>

                      <form onSubmit={handleSubmit(sendEmail)} ref={form}>
                      <label htmlFor="nom" className="text-white fontCust3 tracking-widest text-xl md:text-lg mb-3">
          Nom
        </label>
        <div className="text-xl w-full ">
          <input
            {...register("name", {
              required: "Veuillez entrer un nom.",
              pattern: {
                value: /^(?=.{2,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
                message: "Charactères invalides ou nom trop court.",
              }
            })}
            id="name"
            name="name"
            type="text"
            placeholder="Votre nom"
            autoComplete="off"
            className={`mb-2 mt-2 block w-full md:w-5/6 fontCust3 tracking-widest py-2 pl-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              !errors.name && dirtyFields.name && "!bg-green-50"
            }`}
          />
          <span class="text-xs italic text-yellow-400 fontCust3 font-semibold">{errors.name?.message}</span>
          </div>
                    

          <label htmlFor="email" className="text-white fontCust3 tracking-widest text-xl md:text-lg mb-3">
          Email
        </label>
        <div className="text-xl w-full ">
          <input
            {...register("email", {
              required: "Veuillez entrer une adresse mail.",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Adresse mail invalide.",
              }
            })}
            id="email"
            name="email"
            type="email"
            placeholder="Votre email"
            autoComplete="off"
            className={`mb-2 mt-2 block w-full md:w-5/6 fontCust3 tracking-widest py-2 pl-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              !errors.email && dirtyFields.email && "!bg-green-50"
            }`}
          />
          <span class="text-xs italic text-yellow-400 fontCust3 font-semibold">{errors.email?.message}</span>
        </div>
        

                    <label htmlFor="message" className="text-white fontCust3 tracking-widest text-xl md:text-lg mb-3">
          Message
        </label>
        <div className="text-xl w-full">
          <textarea
            {...register("message", {
              required: "Veuillez entrer un message.",
              pattern: {
                value:  /^.{50,}$/,
                message: "Message trop court.",
              }
            })}
            id="message"
            name="message"
            type="text"
            placeholder="Dites nous ce à quoi vous pensez..."
            autoComplete="off"
            style={{resize:"none"}}
            className={`mb-2 mt-2 block w-full max-h-36 h-36 md:w-5/6 fontCust3 tracking-widest py-2 pl-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" ${
              !errors.message && dirtyFields.message && "!bg-green-50"
            }`}
          />
          <span class={"text-xs italic text-yellow-400 fontCust3 font-semibold"}>{errors.message?.message}</span>
        </div>
        
        <ReCAPTCHA
        className="w-full md:w-5/6"
        onChange={()=>setCliquable(true)}
        ref={recaptchaRef}
        sitekey="6Ld0MOAfAAAAAGlPuUBXteca99OJmGTRaTCDCJ2o"
      />
      <span class={warning ? "text-xs italic text-yellow-400 fontCust3 font-semibold" : "hidden"}>Veuillez compléter le captcha</span>
                <div type="submit" class={isVisible ? "hidden flex flex-row flex flex-row border-2 rounded border-green-700 bg-green-800 hover:bg-green-700 w-full justify-center sm:bg-transparent items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer hover:bg-green-800 py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200 mt-4" : "flex flex-row flex flex-row border-2 rounded border-green-700 bg-green-800 hover:bg-green-700 w-full justify-center sm:bg-transparent items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer hover:bg-green-800 py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200 mt-4"}>    
        <input type="submit" class="text-xl font-bold text-white fontCust2 tracking-widest  hover:cursor-pointer"/> 
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                        </div>



                        <div class={isVisible ? "flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 fontCust3 mt-6" : "hidden  mt-6 flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 fontCust3" }>
        <div class="flex items-center justify-center w-12 bg-green-600">
            <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
            </svg>
        </div>
        
        <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
                <span class="font-semibold text-emerald-500 ">Confirmé</span>
                <p class="text-sm text-gray-600">Votre demande a été prise en compte !</p>
            </div>
        </div>
    </div>

        {/* <div class="flex flex-row border-2 rounded border-green-700 bg-green-800 hover:bg-green-700 w-full justify-center sm:bg-transparent items-center border-2 sm:w-fit rounded sm:border-white hover:cursor-pointer hover:bg-green-800 py-2 px-4  hover:border-transparent sm:hover:scale-x-110 transition duration-200">
                      <div class="text-xl font-bold text-white fontCust2 tracking-widest ">Envoyer</div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    </div> */}


                    </form>

                    </div>
                    
                    
                  </div>
              </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-center h-fit w-full bg-red-900">
          
          
          <footer  class="text-center lg:text-left bg-red-900 text-white fontCust3 ">
  <div class="flex justify-center items-center lg:justify-between p-6 border-b border-t border-gray-300">
    <div class="mr-12 hidden text-lg lg:block">
      <span>Contactez nous sur nos réseaux :</span>
    </div>
    <div class="flex justify-center">
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
          class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512">
          <path fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
          </path>
        </svg>
      </a>
      <a class="twitter-share-button mr-6 text-white"
  href="https://twitter.com/intent/tweet?text=Prenez%20votre%20s%C3%A9curit%C3%A9%20en%20main%20avec%20<b>IGUARD</b>%20%3A%0A<b>IGUARD</b>incendie.fr"
  data-size="large">
      
      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
        class="w-4 twitter-share-button" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
        </path>
      </svg>
    </a>
      
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
          <path fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
          </path>
        </svg>
      </a>
      <a href="#!" class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
          </path>
        </svg>
      </a>
      <a href="#!" class="text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
          </path>
        </svg>
      </a>
    </div>
  </div>
  <div class="mx-6 py-10 text-center md:text-left">
    <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div class="">
        <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
          
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
</svg>
          <b>IGUARD</b>, sécurité incendie
        </h6>
        <p>
          Votre sécurité est notre priorité et nous nous en portant garants.
        </p>
      </div>
      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Produits
        </h6>
        <p class="mb-4">
          <div onClick={()=>goToTab(1)} class="text-white  hover:cursor-pointer hover:text-gray-300 transition duration-200">Extincteurs</div>
        </p>
        <p class="mb-4">
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Porte coupe-feu</div>
        </p>
        <p class="mb-4">
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Trappes</div>
        </p>
        <p>
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Rideaux</div>
        </p>
      </div>
      

      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Liens utiles
        </h6>
        <p class="mb-4">
          <div class="text-white hover:text-gray-300 hover:cursor-not-allowed transition duration-200">Boutique</div>
        </p>
        <p class="mb-4">
        <Link to="/devis">
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Devis urgent</div>
          </Link>
        </p>
        <p class="mb-4">
          <div onClick={goToNorm} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Certifications</div>
        </p>
        <p>
          <div  onClick={()=>goToTab(8)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Formations</div>
        </p>
      </div>
      <div class="hidden sm:block">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Contact
        </h6>
        <Link to="/plan">
        <p class="flex items-center justify-center md:justify-start mb-4 hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor"
              d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
            </path>
          </svg>
         8 Quai Adrien Agnés, 93300 Aubervilliers
        </p>
        </Link>
        <a href="mailto:contact@iguardincendie.fr" class="flex items-center justify-center md:justify-start mb-4 hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
            </path>
          </svg>
          {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`}
        </a>
        <a href="tel:+33134291449" class="flex items-center justify-center md:justify-start mb-4 tracking-widest hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
            class="w-4 mr-4 transform rotate-90" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
            </path>
          </svg>
          01 34 29 14 49
        </a>
        <p class="flex items-center justify-center md:justify-start">
          
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-12a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 1 0 1.415-1.415L11 9.586V6z"
                  />
                </svg>
          de 8h à 18h30 en semaine
        </p>
      </div>

      {/** Layout contact mobile */}
      <div class="flex flex-row w-full sm:hidden">
      <div class="flex flex-col w-full sm:hidden">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Produits
        </h6>
        <p class="mb-4">
          <div onClick={()=>goToTab(1)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Extincteurs</div>
        </p>
        <p class="mb-4">
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Porte coupe-feu</div>
        </p>
        <p class="mb-4">
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Trappes</div>
        </p>
        <p>
          <div  onClick={()=>goToTab(7)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Rideaux</div>
        </p>
      </div>
      <div class="flex flex-col w-full sm:hidden">
        <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
          Liens utiles
        </h6>
        <p class="mb-4">
          <div class="text-white hover:text-gray-300 hover:cursor-not-allowed transition duration-200">Boutique</div>
        </p>
        <p class="mb-4">
        <Link to="/devis">
          <div class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Devis urgent</div>
        </Link>
        </p>
        <p class="mb-4">
          <div onClick={goToNorm} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Certifications</div>
        </p>
        <p>
          <div  onClick={()=>goToTab(8)} class="text-white hover:cursor-pointer hover:text-gray-300 transition duration-200">Formations</div>
        </p>
      </div>
      </div>
      <div class="block  sm:hidden pl-12">
        <h6 class="uppercase font-semibold mb-4 flex justify-start">
          Contact
        </h6>
        <Link to="/plan">
        <p class="flex items-center justify-start mb-4 text-sm hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor"
              d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
            </path>
          </svg>
          8 Quai Adrien Agnés, 93300 Aubervilliers
        </p>
        </Link>
        <a href="mailto:contact@iguardincendie.fr" class="flex items-center justify-start mb-4 hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
            </path>
          </svg>
          {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`}
        </a>
        <a href="tel:+33134291449" class="flex items-center justify-start mb-4 tracking-widest hover:cursor-pointer hover:text-gray-300 transition duration-200">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
            class="w-4 mr-4 transform rotate-90" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
            </path>
          </svg>
          01 34 29 14 49
        </a>
        <p class="flex items-center justify-start">
          
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 mr-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-12a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 1 0 1.415-1.415L11 9.586V6z"
                  />
                </svg>
          de 8h à 18h30 en semaine
        </p>
      </div>
    </div>
  </div>
</footer>
       
         
          </div>
          <div class="w-full cred">
        <div className="container text-sm px-6 py-4 mx-auto text-center">
          <div>
            <p className="text-center text-white fontCust3">
              Réalisé par Sophiane L — Copyright © 2022 — Tous droits réservés
            </p>
          </div>
        </div>
      </div>
  </div>

  



)
};

export default Home;