import React from 'react'
import { useRef, useState, useEffect } from 'react';
import "../assets/fonts/fonts.css";


const Carousel = ( { images, refCont }) => {
    const carousel = useRef();
    const [count, setCount]= useState(0);
  const incrCarousel = (delta) =>{
        if(!carousel.current) return;
        const width = carousel.current.offsetWidth;

        if(count + delta > images.length -1){
            setCount(0);
            carousel.current.scrollTo(0, 0);
            return;
        }else if (count + delta < 0){
            setCount(images.length -1);
            carousel.current.scrollTo(carousel.current.scrollLeft + width * images.length-1, 0);
        }
        
        carousel.current.scrollTo(carousel.current.scrollLeft + width * delta, 0);
        setCount(c => c + delta);
    
  }

  useEffect(() => {
        const interval = setInterval(() => {
         incrCarousel(1);
        }, 5000);
        return () => clearInterval(interval);
      });

      const goToCont = () =>{
        if(refCont.current){
          refCont.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
     } 

  return (
    <div className="carousel-container fontCust2">
        <div className="carousel-btn left-btn hover:border-white/75" onClick={()=>incrCarousel(-1)}></div>
        <div className="carousel-btn right-btn hover:border-white/75" onClick={()=>incrCarousel(1)}></div>
        <div className="carousel" ref={carousel}>
            {images.map(((img, idx) => (
                    <div className={idx === count ? "carousel-item active overflow-y-hidden" : "carousel-item overflow-y-hidden"} key={`${idx}-${img.title}`}>
                        <picture className={idx === count ? "carousel-item active" : "carousel-item"} key={`${idx}-${img.title}`} alt="slider du carousel">
                          <source srcSet={img.srcw} type="image/webp" />
                          <img src={img.src} alt="slider du carousel"></img>
                    </picture>
                        <div class="inset-center  text-center text-white w-full">
                            <h1 class=" xl:text-5xl text-2xl fontCust2 tracking-wider font-bold">{img.title}</h1>
                            <h2 class="xl:text-lg text-md fontCust2 tracking-widest font-bold py-3">Toute la gamme de protection et sécurité incendie.</h2>
                            <div class="w-full flex justify-center items-center mt-3">
                            <button onClick={goToCont} class="animate-bounce hover:animate-none hover:bg-gray-200/30 hover:border-transparent hover:scale-x-110 transition duration-200 text-xl font-bold text-white border-2 rounded border-white hover:cursor-pointer py-2 px-4 flex flex-row">Nous contacter
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white pt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                            </div>
                            
                        </div>
                    </div>
            )))}
        </div>
    </div>
  );
};

export default Carousel;
