import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../assets/scroll.css';
import Footer from './Footer';
import Map from './Map';
import Home from './Home';
import "../assets/fonts/fonts.css";
import Logo from "../assets/logo_iguard.png";
import cross from "../assets/cross.png";
import crossw from "../assets/cross.webp";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
  

const Plan = () => {
  const boite = useRef();
  const navRef = React.useRef(null);
  const [isVisible, setVisible] = useState(false);

  const [toggled, setToggled] = useState(true);

  const [ferme, setFerme] = useState(true);
  const [fermeb, setFermeb] = useState(true);


  const showSidebar = (e) => {
      navRef.current.classList.remove("translate-x-96");
      boite.current.classList.remove("hidden");
    };
  
    const hideSidebar = (e) => {
      navRef.current.classList.add("translate-x-96");
      boite.current.classList.add("hidden");
    };
  
    const useClickOutside = (handler) => {
      let outsideSidebar = useRef();
  
      useEffect(() => {
        const maybeHandler = (event) => {
          if (!outsideSidebar.current.contains(event.target)) {
            handler();
          }
        };
  
        document.addEventListener("mousedown", maybeHandler);
  
        return () => {
          document.removeEventListener("mousedown", maybeHandler);
        };
      });
  
      return outsideSidebar;
    };
  
    let outsideSidebar = useClickOutside(() => {
      hideSidebar();
    });




  const [navbar, setNavbar] = useState(false);

const changeBackground = () => {
  if (window.scrollY >= 118) {
    setNavbar(true);
  } else {
    setNavbar(false);
  }
};

window.addEventListener("scroll", changeBackground);

  return (
    <div class="global_container">
        <div
            class="h-screen w-screen fixed opacity-100 z-50  top-0 backdrop-filter backdrop-blur-sm backdrop-brightness-75 md:hidden hidden"
            ref={boite}
          ></div>
    <div ref={outsideSidebar}>
            <div
              class="sidebar min-h-screen xl:translate-x-96 flex fixed right-0 transform translate-x-96 transition duration-200 ease-in-out z-50"
              ref={navRef}
            >
              <div class=" bg-white w-72">
                <div class="bg-red-800 text-white border-b  text-xl flex items-center h-20">
                  <button >
                  <picture class="h-6 w-6 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" alt="fermer sidebar">
                          <source srcSet={crossw} type="image/webp" />
                          <img onClick={hideSidebar} src={cross} alt="fermer sidebar" class="h-6 w-6 -left-10 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" ></img>
                    </picture>
                  </button>
                  <div class="flex justify-center items-center w-full h-full">
                    <div class="fontCust3 text-2xl font-semibold">IGUARD</div>
                  </div>
                </div>
                <nav>
                <Link to="/"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b  hover:text-blue-700 hover:bg-gray-100 transition duration-200"
                  >
                    Présentation
                  </Link>
                  <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200">
                    Nos services
                  </HashLink>
                  <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200">
                    Certification normes et garanties
                  </HashLink>
                  <Link to="/devis"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Devis urgent
                  </Link>
                  <Link to="/recrutement"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Recrutement
                  </Link>
                  <Link to="/plan"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Plan d'accès
                  </Link>
                  <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Nous contacter
                  </HashLink>
                </nav>
              </div>
            </div>
          </div>
<div class="h-8 w-full bg-red-700">
  <div class="h-full lg:w-5/6 w-full flex justify-center items-center md:justify-end">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
  </svg>
  
    <a href="tel:+33134291449" class="text-white fontCust3 font-bold tracking-wider mr-6 gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> 01.34.29.14.49 </a>
    
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
</svg>
<a href="mailto:contact@iguardincendie.fr" class=" text-white fontCust3 font-bold tracking-wider gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`} </a>
    
  </div>
</div>




<nav class={navbar ? "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10 fixed z-40 w-full -mt-8" : "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10" }> 
      <div class="container flex flex-wrap justify-between items-center mx-auto">
      <div class="h-full flex items-center">
        <Link to="/">
            <img src={Logo} alt="logo entreprise iguard" className="h-16 hover:opacity-80 hover:cursor-pointer transition duration-200" />
            </Link>
            </div>
            <div onClick={showSidebar} class="lg:hidden flex h-full justify-end items-center pr-6 hover:cursor-pointer hover:-translate-x-2 transition duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            </div>
      <div class="hidden justify-center items-center w-full lg:flex md:w-auto md:order-1" id="mobile-menu-2">
        <ul class="flex flex-col md:flex-row md:space-x-4 md:mt-0 lg:text-md xl:text-lg text-sm md:font-medium">
          
          <li>
          <Link to="/">
            <a href="#" class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Présentation</a>
          </Link>
          </li>
          <li>
          
            <div class="drpd">
            <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} class="link">
            <div class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nos services ▾ </div>
            </HashLink>
            <div class="drpd-menu w-64 z-50 fontCust3 ">
            <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3">Formation</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Audit</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Moyens d'extinction</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Désenfumage</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Signalétique</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Détection incendie</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Éclairage de sécurité</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Robinet incendie armé</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Porte coupe-feu</div>
              </HashLink>
            </div>
            </div>
            
          </li>
          <li>
          <div class="drpd">
          <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} class="link">
            <a href="#" class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Certifications normes et garanties ▾ </a>
            </HashLink>
            <div class="drpd-menu w-64 xl:w-80 z-50 fontCust3">
            <HashLink to="/#references" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div class="drpd-menu-item py-2 rounded-xl px-3">Nos références</div>
              </HashLink>
            </div>
            </div>
          </li>
          <li>
            <Link to="/devis" class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Devis urgent</Link>
          </li>
          <li>
          <div class="drpd">
            <a class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 hover:cursor-not-allowed ">Boutique ▾ </a>
            <div class="drpd-menu-b w-64 z-50 fontCust3">
              <div class="drpd-menu-item-b pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3 hover:cursor-not-allowed">Extincteurs</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Détecteurs de fumée</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Alarmes incendie</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">BAES BAEH</div>
              <div class="drpd-menu-item-b px-3 rounded-bl-xl rounded-br-xl py-1 hover:cursor-not-allowed">Accessoires extincteur</div>
            </div>
            </div>
          </li>
          <li>
          <div class="drpd">
          <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} class="link">
            <button class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nous contacter ▾ </button>
            </HashLink>
            <div class="drpd-menu-n w-64 z-50 fontCust3">
            <Link to="/plan">
              <div class="drpd-menu-item py-2 rounded-tl-xl rounded-tr-xl px-3">Plan d'acces</div>
              </Link>
              <Link to="/recrutement">
              <div class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Recrutement</div>
              </Link>
            </div>
            </div>
          </li>
          
          {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg> */}
        </ul>
      </div>
      </div>
</nav>
        <div class="w-full flex justify-center py-3 pt-10 pb-20 md:py-20">
        <div class="w-5/6 h-fit flex flex-col-reverse md:flex-row fontCust3">
        <div class="md:w-2/6 w-full  h-full py-6 pb-10 bg-red-800 text-white">
        <h1 class="w-contain font-semibold text-center sm:text-left text-xl sm:text-2xl md:text-3xl ml-3">
                Itinéraire île-de-France
            </h1>
            <div class="mt-2 w-full">
                <div class="flex h-full items-center">
                    <div onClick={()=> setToggled(true)} class={toggled ? "ml-3 w-8 h-8 bg-white rounded flex justify-center items-center text-black hover:cursor-pointer transition duration-200 hover:bg-white hover:text-black" : "ml-3 w-8 h-8 cred rounded flex justify-center items-center text-white hover:cursor-pointer transition duration-200 hover:bg-white hover:text-black"} >
                    <svg version="1.1" id="car-15" xmlns="http://www.w3.org/2000/svg" class="h-5" viewBox="0 0 15 15" fill="currentColor">
                        <path d="M14,7c-0.004-0.6904-0.4787-1.2889-1.15-1.45l-1.39-3.24l0,0l0,0l0,0C11.3833,2.1233,11.2019,2.001,11,2H4&#xA;&#x9;C3.8124,2.0034,3.6425,2.1115,3.56,2.28l0,0l0,0l0,0L2.15,5.54C1.475,5.702,0.9994,6.3059,1,7v3.5h1v1c0,0.5523,0.4477,1,1,1&#xA;&#x9;s1-0.4477,1-1v-1h7v1c0,0.5523,0.4477,1,1,1s1-0.4477,1-1v-1h1V7z M4.3,3h6.4l1.05,2.5h-8.5L4.3,3z M3,9C2.4477,9,2,8.5523,2,8&#xA;&#x9;s0.4477-1,1-1s1,0.4477,1,1S3.5523,9,3,9z M12,9c-0.5523,0-1-0.4477-1-1s0.4477-1,1-1s1,0.4477,1,1S12.5523,9,12,9z"/>
                    </svg>
                    </div>
                    <div onClick={()=> setToggled(false)} class={toggled ? "ml-3 w-8 h-8 cred rounded flex justify-center items-center text-white hover:cursor-pointer transition duration-200 hover:bg-white hover:text-black" : "ml-3 w-8 h-8 bg-white rounded flex justify-center items-center text-black hover:cursor-pointer transition duration-200 hover:bg-white hover:text-black" }> 
                    <svg viewBox="0 0 16 19" version="1.1" class="h-5" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill="currentColor">
                        <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rounded" transform="translate(-852.000000, -3212.000000)">
                                <g id="Maps" transform="translate(100.000000, 3068.000000)">
                                    <g id="-Round-/-Maps-/-train" transform="translate(748.000000, 142.000000)">
                                        <g>
                                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M12,2 C8,2 4,2.5 4,6 L4,15.5 C4,17.43 5.57,19 7.5,19 L6.35,20.15 C6.04,20.46 6.26,21 6.71,21 L7.8,21 C7.93,21 8.06,20.95 8.15,20.85 L10,19 L14,19 L15.85,20.85 C15.94,20.94 16.07,21 16.2,21 L17.29,21 C17.74,21 17.96,20.46 17.64,20.15 L16.5,19 C18.43,19 20,17.43 20,15.5 L20,6 C20,2.5 16,2 12,2 Z M7.5,17 C6.67,17 6,16.33 6,15.5 C6,14.67 6.67,14 7.5,14 C8.33,14 9,14.67 9,15.5 C9,16.33 8.33,17 7.5,17 Z M11,10 L6,10 L6,6 L11,6 L11,10 Z M16.5,17 C15.67,17 15,16.33 15,15.5 C15,14.67 15.67,14 16.5,14 C17.33,14 18,14.67 18,15.5 C18,16.33 17.33,17 16.5,17 Z M18,10 L13,10 L13,6 L18,6 L18,10 Z" id="🔹-Icon-Color"  fill="currentColor"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                    
                </div>
                <div class={toggled ? "visible" : "hidden"}>
                <div class="w-full text-lg ml-3 mt-4 font-semibold">En voiture :</div>
                <div class="w-contain mx-3 mt-1 pb-4 pt-2 bg-white h-contain text-black">
                    <div class="flex w-full ">
                    <svg version="1.1" id="car-15" xmlns="http://www.w3.org/2000/svg" class="h-5 mt-1.5 ml-1" viewBox="0 0 15 15" fill="currentColor">
                        <path d="M14,7c-0.004-0.6904-0.4787-1.2889-1.15-1.45l-1.39-3.24l0,0l0,0l0,0C11.3833,2.1233,11.2019,2.001,11,2H4&#xA;&#x9;C3.8124,2.0034,3.6425,2.1115,3.56,2.28l0,0l0,0l0,0L2.15,5.54C1.475,5.702,0.9994,6.3059,1,7v3.5h1v1c0,0.5523,0.4477,1,1,1&#xA;&#x9;s1-0.4477,1-1v-1h7v1c0,0.5523,0.4477,1,1,1s1-0.4477,1-1v-1h1V7z M4.3,3h6.4l1.05,2.5h-8.5L4.3,3z M3,9C2.4477,9,2,8.5523,2,8&#xA;&#x9;s0.4477-1,1-1s1,0.4477,1,1S3.5523,9,3,9z M12,9c-0.5523,0-1-0.4477-1-1s0.4477-1,1-1s1,0.4477,1,1S12.5523,9,12,9z"/>
                    </svg>

                    <div class="text-slate-900 font-bold ml-3 mt-0.5">Depuis Gare du Nord :</div>
                </div>
                
                <div class="flex w-full">
                <div class="h-contain ml-3 border-l-4 border-black"></div>
                <div clas="flex flex-row w-full ">
                <div class="ml-5 text-gray-600 font-semibold mt-2">• Prendre la direction est (6m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche (211m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche (228m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Rejoindre A86 sur 2.5km.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à droite sur Boulevard de la Chapelle (75m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-2">• Tourner à gauche sur Rue Marx Dormoy (1615m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Continuer sur N1 Avenue de la Porte de la Chapelle (104m).</div>
                <SlideDown className={'my-dropdown-slidedown'} closed={ferme}>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche sur N1 Avenue de la Porte de la Chapelle (1774m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à droite sur Rue du Landy (977m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche sur Quai Adrien Agnès (196m).</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Arrivée à Quai Adrien Agnès.</div>
                </SlideDown>
                </div>
                </div> 
                <div class="flex w-full items-center mt-2 ">
                <div class=" h-5 ml-3 border-l-4 border-black border-dotted"></div>
                <div class="flex w-full items-center text-purple-700 font-semibold hover:cursor-pointer hover:text-blue-900">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5  ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
</svg>
<div onClick={()=>setFerme(!ferme)}class=" ml-1">{ ferme ? "Afficher plus d'étapes" : "Afficher moins d'étapes"}</div>
                </div>
                
                </div>

                <div class="flex w-full ">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-1.5 ml-1" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clip-rule="evenodd" />
</svg>

                    <div class="text-slate-900 font-bold ml-3 mt-0.5">Iguard, 8 Quai Adrien Agnés, 93300 Aubervilliers</div>
                </div>
                    </div>
                    </div>

                    <div class={toggled ? "hidden" : "visible"}>
                <div class="w-full text-lg ml-3 mt-4 font-semibold">En transports :</div>
                <div class="w-contain mx-3 mt-1 pb-4 pt-2 bg-white h-contain text-black">
                    <div class="flex w-full ">
                    <svg viewBox="0 0 16 19" version="1.1" class="h-4 mt-1.5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill="currentColor">
                        <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rounded" transform="translate(-852.000000, -3212.000000)">
                                <g id="Maps" transform="translate(100.000000, 3068.000000)">
                                    <g id="-Round-/-Maps-/-train" transform="translate(748.000000, 142.000000)">
                                        <g>
                                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                            <path d="M12,2 C8,2 4,2.5 4,6 L4,15.5 C4,17.43 5.57,19 7.5,19 L6.35,20.15 C6.04,20.46 6.26,21 6.71,21 L7.8,21 C7.93,21 8.06,20.95 8.15,20.85 L10,19 L14,19 L15.85,20.85 C15.94,20.94 16.07,21 16.2,21 L17.29,21 C17.74,21 17.96,20.46 17.64,20.15 L16.5,19 C18.43,19 20,17.43 20,15.5 L20,6 C20,2.5 16,2 12,2 Z M7.5,17 C6.67,17 6,16.33 6,15.5 C6,14.67 6.67,14 7.5,14 C8.33,14 9,14.67 9,15.5 C9,16.33 8.33,17 7.5,17 Z M11,10 L6,10 L6,6 L11,6 L11,10 Z M16.5,17 C15.67,17 15,16.33 15,15.5 C15,14.67 15.67,14 16.5,14 C17.33,14 18,14.67 18,15.5 C18,16.33 17.33,17 16.5,17 Z M18,10 L13,10 L13,6 L18,6 L18,10 Z" id="🔹-Icon-Color"  fill="currentColor"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <div class="text-slate-900 font-bold ml-3 mt-0.5">Depuis Gare du Nord :</div>
                </div>
                
                <div class="flex w-full">
                <div class="h-contain ml-3 border-l-4 border-black"></div>
                <div clas="flex flex-row w-full ">
                <div class="ml-5 text-gray-600 font-semibold mt-2">• Prendre bus 35 direction Marie d'Aubervilliers.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Descendre à Ordener - Marx Dormoy.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Prendre bus 12 direction Marie d'Aubervilliers.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Descendre à Front Populaire.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Prendre bus 139 direction La Plaine - Stade de France.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-2">• Descendre à Murger.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Prendre la direction est sur Rue du Landy.</div>
                <SlideDown className={'my-dropdown-slidedown'} closed={fermeb}>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche sur Rue Paul Lafargue.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à droite sur Rue Gaétan Lamy.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Tourner à gauche sur Quai Adrien Agnès.</div>
                <div class="ml-5 text-gray-600 font-semibold mt-1">• Arrivée à Quai Adrien Agnès.</div>
                </SlideDown>
                </div>
                </div> 
                <div class="flex w-full items-center mt-2 ">
                <div class=" h-5 ml-3 border-l-4 border-black border-dotted"></div>
                <div class="flex w-full items-center text-purple-700 font-semibold hover:cursor-pointer hover:text-blue-900">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5  ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
</svg>
<div onClick={()=>setFermeb(!fermeb)}class=" ml-1">{ fermeb ? "Afficher plus d'étapes" : "Afficher moins d'étapes"}</div>
                </div>
                
                </div>

                <div class="flex w-full ">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-1.5 ml-1" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clip-rule="evenodd" />
</svg>

                    <div class="text-slate-900 font-bold ml-3 mt-0.5">Iguard, 8 Quai Adrien Agnés, 93300 Aubervilliers</div>
                </div>
                    </div>
                    </div>
                
            </div>
        </div>
        <div class="md:w-4/6 w-full mb-3 md:mb-0">
            <Map/>
        </div>
          </div>
          </div>
          
          <div class="lg:hidden">
          <Footer/>
          </div>
          </div>
  )
}

export default Plan;