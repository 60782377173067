import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../assets/scroll.css';
import Footer from './Footer';
import "../assets/fonts/fonts.css";
import Logo from "../assets/logo_iguard.png";
import Logow from "../assets/logo_iguard.webp";
import cross from "../assets/cross.png";
import crossw from "../assets/cross.webp";
import Paper from "../assets/devis.jpg";
import Paperw from "../assets/devis.webp";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";








// The following component is an example of your existing Input Component
const Input = ({ name, label, register, placeholder, required }) => (
  <>
    <div class="text-xl flex flex-col w-full mb-2">
        <label class="font-semibold">{label}</label>
        <input {...register(name, { required: false })} class="w-full rounded border border-gray-300 mt-1 py-1 pl-2" placeholder={placeholder}/>
        
    </div>
  </>
);



  

const Devis = () => {
  const boite = useRef();
  const navRef = React.useRef(null);
  const [isVisible, setVisible] = useState(false);
  const recaptchaRef = React.createRef();
  const [cliquable, setCliquable] = useState(false);
  const [warning, setWarning] = useState(false);

  const showSidebar = (e) => {
      navRef.current.classList.remove("translate-x-96");
      boite.current.classList.remove("hidden");
    };
  
    const hideSidebar = (e) => {
      navRef.current.classList.add("translate-x-96");
      boite.current.classList.add("hidden");
    };
  
    const useClickOutside = (handler) => {
      let outsideSidebar = useRef();
  
      useEffect(() => {
        const maybeHandler = (event) => {
          if (!outsideSidebar.current.contains(event.target)) {
            handler();
          }
        };
  
        document.addEventListener("mousedown", maybeHandler);
  
        return () => {
          document.removeEventListener("mousedown", maybeHandler);
        };
      });
  
      return outsideSidebar;
    };
  
    let outsideSidebar = useClickOutside(() => {
      hideSidebar();
    });



    const { register, handleSubmit, reset, formState: { errors, dirtyFields } } = useForm({
      mode: "onChange",
      defaultValues: {
        email: "",
        phone: "",
        message: "",
        name : "",
      }
      });
    const form = useRef();

    const showConfirm = () => {
      if(isVisible){return;}
      else{
        setVisible(true);
        setTimeout(()=> setVisible(false) , 2500);
      }
    }
    
  const sendEmail = (data) => {
    if(cliquable){
    emailjs.sendForm('service_4aahyeb', 'template_kxgm15g', form.current, '3ROAX5nFzhZgCe45b')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      reset();
      showConfirm();
    }else {
      showWarning();
      return;
    }
    
  };

  const [navbar, setNavbar] = useState(false);

const changeBackground = () => {
  if (window.scrollY >= 118) {
    setNavbar(true);
  } else {
    setNavbar(false);
  }
};

window.addEventListener("scroll", changeBackground);



const showWarning = () => {
  if(warning){return;}
  else{
    setWarning(true);
    setTimeout(()=> setWarning(false) , 5000);
  }
}

  return (
    <div class="global_container ">
        <div
            class="h-screen w-screen fixed opacity-100 z-50  top-0 backdrop-filter backdrop-blur-sm backdrop-brightness-75 md:hidden hidden"
            ref={boite}
          ></div>
    <div ref={outsideSidebar}>
            <div
              class="sidebar min-h-screen xl:translate-x-96 flex fixed right-0 transform translate-x-96 transition duration-200 ease-in-out z-50"
              ref={navRef}
            >
              <div class=" bg-white w-72">
                <div class="bg-red-800 text-white border-b  text-xl flex items-center h-20">
                  <button >
                  <picture class="h-6 w-6 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" alt="fermer sidebar">
                          <source srcSet={crossw} type="image/webp" />
                          <img onClick={hideSidebar} src={cross} alt="fermer sidebar" class="h-6 w-6 -left-10 fixed transform hover:-translate-x-1 transition duration-300 ease-in-out" ></img>
                    </picture>
                  </button>
                  <div class="flex justify-center items-center w-full h-full">
                    <div class="fontCust3 text-2xl font-semibold">IGUARD</div>
                  </div>
                </div>
                <nav>
                <Link to="/"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b  hover:text-blue-700 hover:bg-gray-100 transition duration-200"
                  >
                    Présentation
                  </Link>
                  <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200">
                    Nos services
                  </HashLink>
                  <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200">
                    Certification normes et garanties
                  </HashLink>
                  <Link to="/devis"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Devis urgent
                  </Link>
                  <Link to="/recrutement"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Recrutement
                  </Link>
                  <Link to="/plan"
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Plan d'accès
                  </Link>
                  <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}
                    class="block w-full text-center text-md py-6 px-4 fontCust3 border-b hover:text-blue-700  hover:bg-gray-100 transition duration-200"
                  >
                    Nous contacter
                  </HashLink>
                </nav>
              </div>
            </div>
          </div>
<div class="h-8 w-full bg-red-700">
  <div class="h-full lg:w-5/6 w-full flex justify-center items-center md:justify-end">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
  </svg>
    <a href="tel:+33134291449" class="text-white fontCust3 font-bold tracking-wider mr-6 gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> 01.34.29.14.49 </a>
    
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white mr-2" viewBox="0 0 20 20" fill="currentColor">
  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
</svg>
<a href="mailto:contact@iguardincendie.fr" class=" text-white fontCust3 font-bold tracking-wider gFoldFont hover:cursor-pointer hover:text-gray-300 transition duration-200"> {`\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0067\u0075\u0061\u0072\u0064\u0069\u006e\u0063\u0065\u006e\u0064\u0069\u0065\u002e\u0066\u0072`} </a>
    
  </div>
</div>




<nav class={navbar ? "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10 fixed z-40 w-full -mt-8" : "bg-white h-24 flex flex-row px-2 sm:px-4 py-2.5  border-b-2 border-gray-600/10" }> 
      <div class="container flex flex-wrap justify-between items-center mx-auto">
      <div class="h-full flex items-center">
        <Link to="/">
            <picture class="h-16" alt="logo entreprise iguard">
                          <source srcSet={Logow} type="image/webp" />
                          <img src={Logo} alt="logo entreprise iguard" class="h-16 hover:opacity-80 hover:cursor-pointer transition duration-200" ></img>
                    </picture>
            </Link>
            </div>
            <div onClick={showSidebar} class="lg:hidden flex h-full justify-end items-center pr-6 hover:cursor-pointer hover:-translate-x-2 transition duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            </div>
      <div class="hidden justify-center items-center w-full lg:flex md:w-auto md:order-1" id="mobile-menu-2">
        <ul class="flex flex-col md:flex-row md:space-x-4 md:mt-0 lg:text-md xl:text-lg text-sm md:font-medium">
          
          <li>
          <Link to="/">
            <div class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Présentation</div>
          </Link>
          </li>
          <li>
          
            <div class="drpd">
            <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} class="link">
            <div class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nos services ▾ </div>
            </HashLink>
            <div class="drpd-menu w-64 z-50 fontCust3 ">
            <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3">Formation</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Audit</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Moyens d'extinction</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Désenfumage</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Signalétique</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Détection incendie</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Éclairage de sécurité</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 py-2">Robinet incendie armé</div>
              </HashLink>
              <HashLink to="/#nosServ" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div  class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Porte coupe-feu</div>
              </HashLink>
            </div>
            </div>
            
          </li>
          <li>
          <div class="drpd">
          <HashLink to="/#normes" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} class="link">
            <div class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Certifications normes et garanties ▾ </div>
            </HashLink>
            
            <div class="drpd-menu w-64 xl:w-80 z-50 fontCust3">
            <HashLink to="/#references" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              <div class="drpd-menu-item py-2 rounded-xl px-3">Nos références</div>
              </HashLink>
            </div>
            
            </div>
          </li>
          <li>
            <Link to="/devis" class="font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Devis urgent</Link>
          </li>
          <li>
          <div class="drpd">
            <div class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 hover:cursor-not-allowed ">Boutique ▾ </div>
            <div class="drpd-menu-b w-64 z-50 fontCust3">
              <div class="drpd-menu-item-b pt-2 py-2 rounded-tl-xl rounded-tr-xl px-3 hover:cursor-not-allowed">Extincteurs</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Détecteurs de fumée</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">Alarmes incendie</div>
              <div class="drpd-menu-item-b px-3 py-2 hover:cursor-not-allowed">BAES BAEH</div>
              <div class="drpd-menu-item-b px-3 rounded-bl-xl rounded-br-xl py-1 hover:cursor-not-allowed">Accessoires extincteur</div>
            </div>
            </div>
          </li>
          <li>
          <div class="drpd">
          <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} class="link">
            <button class="link font-bold block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 fontCust3 ">Nous contacter ▾ </button>
            </HashLink>
            <div class="drpd-menu-n w-64 z-50 fontCust3">
            <Link to="/plan">
              <div class="drpd-menu-item py-2 rounded-tl-xl rounded-tr-xl px-3">Plan d'acces</div>
              </Link>
              <Link to="/recrutement">
              <div class="drpd-menu-item px-3 rounded-bl-xl rounded-br-xl py-2">Recrutement</div>
              </Link>
            </div>
            </div>
          </li>
          
          {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-blue-700 hover:cursor-pointer transition duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg> */}
        </ul>
      </div>
      </div>
</nav>
        <div class="w-full flex justify-center py-3 pb-20 md:py-20">
        <div class="w-5/6 h-fit flex flex-col md:flex-row fontCust3 shadow-lg p-4">
        
            <div class="flex flex-col sm:flex-row space-x-4 h-full">
            <div class="flex flex-col w-full">
            <div class="flex flex-col">
            <h1 class="w-full font-semibold text-center sm:text-left text-xl sm:text-2xl md:text-3xl mt-2">
                Besoin d'un devis urgent ?
            </h1>
            <div class="hidden lg:flex w-full font-semibold text-center sm:text-left text-sm sm:text-md italic mt-2">
                Nous nous chargons de vous rappeler dans les plus brefs délais.
            </div>
            <div class="lg:hidden w-full text-xs sm:text-md md:text-xl mt-1">
                Envoyez-nous vos coordonnées, votre disponibilité et nous vous contacterons rapidement.
            </div>
            </div>
            <div class="w-full h-fit mt-2 md:mt-6">
            {/* <span class="text-slate-900 tracking-widest text-xl md:text-lg ">Votre nom</span> */}
            <form onSubmit={handleSubmit(sendEmail)} ref={form}>
                <div class="flex flex-col md:flex-row w-full md:space-x-4 ">
                    <div class="flex flex-col w-full">
                    <label htmlFor="email" className="font-semibold text-xl w-full">
          Nom
        </label>
        <div className="text-xl w-full mb-2">
          <input
            {...register("name", {
              required: "Veuillez entrer un nom.",
              pattern: {
                value: /^(?=.{2,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
                message: "Charactères invalides ou nom trop court.",
              }
            })}
            id="name"
            name="name"
            type="text"
            placeholder="Votre nom"
            autoComplete="off"
            className={`w-full rounded border border-gray-300 mt-1 py-1 pl-2 ${
              !errors.name && dirtyFields.name && "!bg-green-50"
            }`}
          />
          <span class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{errors.name?.message}</span>
           </div>
                    </div>
                    <div class="flex flex-col w-full">
                    {/* <Input label="Email" register={register} placeholder="Votre email" name="Email" required />
                    <ErrorMessage
                      errors={errors}
                      name="Email"
                      render={({ message }) => <p class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{message}</p>}
                    /> */}

                  <label htmlFor="email" className="font-semibold text-xl w-full">
          Email
        </label>
        <div className="text-xl w-full mb-2">
          <input
            {...register("email", {
              required: "Veuillez entrer une adresse mail.",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Adresse mail invalide.",
              }
            })}
            id="email"
            name="email"
            type="email"
            placeholder="Votre email"
            autoComplete="off"
            className={`w-full rounded border border-gray-300 mt-1 py-1 pl-2 ${
              !errors.email && dirtyFields.email && "!bg-green-50"
            }`}
          />
        </div>
        <span class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{errors.email?.message}</span>

                    </div>
                    </div>
                    <div class="flex flex-col md:flex-row w-full md:space-x-4 ">
                    <div class="flex flex-col w-full">
                    {/* <Input label="Telephone" register={register} placeholder="Votre numéro de téléphone" name="Telephone" required/>
                    <ErrorMessage
                      errors={errors}
                      name="Telephone"
                      render={({ message }) => <p class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{message}</p>}
                    /> */}

<label htmlFor="telephone" className="font-semibold text-xl w-full">
          Téléphone
        </label>
        <div className="text-xl w-full mb-2">
          <input
            {...register("phone", {
              required: "Veuillez entrer un numéro de téléphone.",
              pattern: {
                value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                message: "Numéro invalide.",
              }
            })}
            id="phone"
            name="phone"
            type="phone"
            placeholder="Votre numéro de téléphone"
            autoComplete="off"
            className={`w-full rounded border border-gray-300 mt-1 py-1 pl-2 ${
              !errors.phone && dirtyFields.phone && "!bg-green-50"
            }`}
          />
        </div>
        <span class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{errors.phone?.message}</span>

                    </div>
                    <Input label="Ville" register={register} placeholder="Votre ville" name="ville" />
                    </div>
                    <div class="flex flex-col md:flex-row w-full md:space-x-4 ">
                    <Input label="Raison sociale" register={register} placeholder="Raison sociale de votre entreprise" name="raison"/>
                    <Input label="Qualité" register={register} placeholder="Qualité au sein de l'entreprise" name="qualite"/>
                    </div>

                    <div class="flex flex-col w-full">
                    {/* <TextArea label="Message" register={register} placeholder="Votre message" name="Message" required/>
                    <ErrorMessage
                      errors={errors}
                      name="Message"
                      render={({ message }) => <p class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{message}</p>}
                    /> */}

<label htmlFor="message" className="font-semibold text-xl w-full">
          Message
        </label>
        <div className="text-xl w-full mb-2">
          <textarea
            {...register("message", {
              required: "Veuillez entrer un message.",
              pattern: {
                value:  /^.{50,}$/,
                message: "Message trop court.",
              }
            })}
            id="message"
            name="message"
            type="text"
            placeholder="Votre message"
            autoComplete="off"
            style={{resize:"none"}}
            className={`rounded border border-gray-300 block w-full max-h-28 h-28 mt-1 py-1 pl-2 ${
              !errors.message && dirtyFields.message && "!bg-green-50"
            }`}
          />
        </div>
        <span class="ml-2 text-xs italic text-red-600 fontCust3 font-semibold">{errors.message?.message}</span>
                    </div>
<div class="flex flex-col">
                    <ReCAPTCHA
        className="w-full md:w-5/6"
        onChange={()=>setCliquable(true)}
        ref={recaptchaRef}
        sitekey="6Ld0MOAfAAAAAGlPuUBXteca99OJmGTRaTCDCJ2o"
      />

<span class={warning ? "text-xs italic text-red-400 fontCust3 font-semibold" : "hidden"}>Veuillez compléter le captcha</span>
</div>
                    <input type="submit" class={isVisible ? "hidden bg-green-600 hover:bg-green-700 hover:cursor-pointer transition duration-200 font-semibold rounded w-full sm:w-fit py-2 px-4 text-white mt-6" : "bg-green-600 hover:bg-green-700 hover:cursor-pointer transition duration-200 font-semibold rounded w-full sm:w-fit py-2 px-4 text-white mt-6" }/> 
                    <div class={isVisible ? "flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 fontCust3 mt-6" : "hidden  mt-6 flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 fontCust3" }>
        <div class="flex items-center justify-center w-12 bg-green-600">
            <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"/>
            </svg>
        </div>
        
        <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
                <span class="font-semibold text-emerald-500 ">Confirmé</span>
                <p class="text-sm text-gray-600">Votre demande a été prise en compte !</p>
            </div>
        </div>
    </div>
            </form>
            </div>
            </div>

<div class="w-0 lg:w-full h-contain  hidden lg:flex">
                <div class="bg-red-800 w-full py-4  text-white"> 
                    <div class="max-h-full fontCust4 tracking-widest border-b border-gray-300/50 pb-1">
                        <div class="text-lg xl:text-xl font-semibold pl-4 pr-2"> Laissez nous vos coordonnées ainsi que vos disponibilités, </div>

                        <div class="text-lg xl:text-xl font-semibold pl-4 pr-2 mt-2"> Iguard se charge de vous rappeler dans les plus brefs délais.</div>
                    </div>

                    <div class="text-sm pl-4 pr-2">
                    <p class="flex items-center justify-center md:justify-start mt-8">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                          class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512">
                          <path fill="currentColor"
                            d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                          </path>
                        </svg>
                           contact@iguard.fr
                      </p>

                      <p class="flex items-center justify-center md:justify-start mt-4">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                            class="w-4 mr-4 transform rotate-90" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="currentColor"
                              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                            </path>
                        </svg>
                        01 34 29 14 49
                      </p>

                      <p class="flex items-center justify-center md:justify-start mt-4">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 mr-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-12a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2.828 2.829a1 1 0 1 0 1.415-1.415L11 9.586V6z"
                          />
                        </svg>
                        de 8h à 18h30 en semaine
                      </p>

                      <p class="flex items-center justify-center md:justify-start mt-4">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                          class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path fill="currentColor"
                            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
                          </path>
                       </svg>
                       8 Quai Adrien Agnés, 93300 Aubervilliers
                      </p>
                      </div>

                      <div class="mt-4 h-16 w-full border-b border-gray-300/50 cred flex items-center justify-center">
                      <div class="flex justify-center">
      <div class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
          class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512">
          <path fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
          </path>
        </svg>
      </div>
      <div class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
          class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
          </path>
        </svg>
      </div>
      <div class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
          <path fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
          </path>
        </svg>
      </div>
      <div class="mr-6 text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
          </path>
        </svg>
      </div>
      <div class="text-white">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
          class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
          </path>
        </svg>
      </div>
    </div>

                      </div>
                </div>
                <div class="w-full h-full bg-red-600">
                  <picture class="w-full object-cover" alt="femme entretien d'embauche" style={{height : "100%"}}>
                          <source srcSet={Paperw} type="image/webp" />
                          <img src={Paper} alt="femme entretien d'embauche" class="w-full object-cover" style={{height : "100%"}}></img>
                    </picture>
                  </div>
            </div>
            </div>
          </div>
          
          </div>
          
          <div class="lg:hidden">
          <Footer/>
          </div>
         
          </div>
  )
}

export default Devis;