import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 48.916880,
      lng: 2.370130,
    },
    zoom: 16,
  };

  render() {
    return (
      <div class="md:h-full md:w-full h-96">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDBAn8E7kDTRdgD8DfylpyFZifpKq7wpPY",
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            lat={this.props.lat}
            lng={this.props.lng}
            className="h-12  text-yellow-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 1 1 9.9 9.9L10 18.9l-4.95-4.95a7 7 0 0 1 0-9.9zM10 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
              clipRule="evenodd"
            />
          </svg>
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
