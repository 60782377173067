import React from 'react'
import "../assets/fonts/fonts.css";
import Flamme from "../assets/flamme.png";
import Flammew from "../assets/flamme.webp";
import { Link } from "react-router-dom";

function ErreurPage() {
  return (
    <div class="global_container bg-gray-300 w-full h-full flex flex-col justify-center items-center">
      <div class="flex flex-row items-center justify-center mb-6">
        <picture class="h-8 sm:h-14 pr-3" alt="flamme du logo Iguard">
                          <source srcSet={Flammew} type="image/webp" />
                          <img src={Flamme} alt="flamme du logo Iguard" class="h-8 sm:h-14 pr-3" ></img>
                    </picture>
        <div class="font-semibold text-2xl sm:text-4xl md:text-6xl  fontCust4 tracking-widest border-r border-gray-500 pr-3">404</div>
        <div class="font-semibold pl-3 fontCust4 tracking-widest text-2xl sm:text-4xl md:text-6xl  ">Page introuvable.</div>
      </div>
      <div class="font-semibold fontCust4 tracking-widest text-sm sm:text-md text-center px-2 md:text-lg lg:text-2xl ">La page que vous tentez d'afficher n'existe pas ou une erreur s'est produite.</div>
      <div class="flex flex-row mt-2 px-2">
      <div class="font-semibold fontCust4 tracking-widest text-sm sm:text-md md:text-lg lg:text-2xl">Vous pouvez retourner à la page d'accueil d'</div>
      <Link to="/">
      <div class="fontCust4 tracking-widest  text-sm sm:text-md md:text-lg lg:text-2xl font-bold text-red-600 hover:text-blue-600 hover:cursor-pointer transition duration-200">Iguard</div>
      </Link>
      <div class="fontCust4 tracking-widest  text-sm sm:text-md md:text-lg lg:text-2xl font-semibold">.</div>
      </div>
    </div>
  )
}

export default ErreurPage;