import Slider1 from "../assets/slider1.jpg";
import Slider1w from "../assets/slider1.webp";
import Slider2 from "../assets/slider2.jpg";
import Slider2w from "../assets/slider2.webp";
import Slider3 from "../assets/slider3.jpg";
import Slider3w from "../assets/slider3.webp";

const data = [
    {
        src: `${Slider1}`,
        srcw: `${Slider1w}`,
        title: "Protection incendie",
    },
    {
        src: `${Slider2}`,
        srcw: `${Slider2w}`,
        title: "Votre confiance se mérite",
    },
    {
        src: `${Slider3}`,
        srcw: `${Slider3w}`,
        title: "Une solution à votre protection",
    },
];

export default data;